import * as React from "react";

import { chakra, useToken } from "@chakra-ui/react";

const HighlightLine2 = ({ color, ...rest }) => {
  const fill = color ? useToken("colors", color) : null;

  return (
    <chakra.svg
      width="23px"
      height="40px"
      viewBox="0 0 23 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...rest}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-46.000000, -45.000000)"
          fill={fill ? fill : "currentColor"}
        >
          <g transform="translate(46.000000, 45.000000)">
            <path d="M2.37311673,32.7557466 C1.89580856,33.5702262 3.77518599,34.6260633 10.3678988,37.2806335 C17.3484436,40.0860633 18.9893191,40.4480543 19.1981089,39.3319457 C19.4665914,37.7934842 3.1785642,31.4586425 2.37311673,32.7557466 Z" />
            <path d="M12.098179,0.689258824 C9.59234241,-0.577723982 9.05537743,-0.306257919 4.67012763,4.4298009 C0.135759961,9.31665158 -0.520500739,10.3725792 0.314777821,11.3076923 C1.8361786,12.9970136 3.44710039,12.0920362 9.35375097,6.33031674 C13.7090739,2.07692308 13.9477549,1.62439819 12.098179,0.689258824 Z" />
            <path d="M21.6442529,16.4057919 C19.8543696,15.4706787 19.0191206,15.500905 15.8271323,16.5868778 C6.37054358,19.8447964 -0.639888638,21.4133032 2.88021751,23.1629864 C4.31212412,23.8869683 6.13189844,23.4646154 17.318642,20.0257014 C23.4938288,18.0951131 24.0307938,17.6727602 21.6442529,16.4057919 Z" />
          </g>
        </g>
      </g>
    </chakra.svg>
  );
};

export default HighlightLine2;
